import React, { useEffect } from "react";

// comps
import { Section, SectionContent, Seo } from "../components";
import Layout from "../components/Layout";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Seo title="Privacy Policy" />
      <Section title="Privacy Policy">
        <SectionContent>
          <p>
            The Velvet Cell is committed to protecting your personal information
            and being transparent about what information we hold about you. In
            order for you to buy books and prints online, and to provide you
            with a personalised shopping experience, we collect certain
            information from you. This Privacy Policy provides a brief
            explanation of what we collect and why. It also explains how we look
            after your data and keep it safe.
          </p>
          <p>
            We have kept this short but if you have any questions at all, please
            do drop us a line at eanna@thevelvetcell.com. If you would like to
            unsubscribe from our list, either email us and we can take you off
            or you can click here to remove yourself. If required we can also
            delete ALL information about you from our systems.
          </p>
          <h4>What personal data we collect, how we use it and why</h4>
          <p>
            When you place an order on our website, we collect your name,
            delivery address, billing address, email address and phone number if
            provided. This is only used to fulfil your order. If we don’t
            collect this personal data, we won’t be able to process and deliver
            your order and comply with our legal obligations. Your details are
            passed to our mailing house, OML, so that your order can be
            delivered via specific courier or postal services. These third
            parties are also subject to their own privacy policies.
          </p>
          <h4>Marketing Communications</h4>
          <p>
            We aim to communicate with you in ways that you find relevant,
            timely and respectful. Only if you have actively chosen to be
            subscribed to our mailing list will you receive emails about new
            books, offers, events and occasional job vacancies. If at any time
            you would like to unsubscribe from our list, click here. We will
            periodically check our mailing lists and can see if emails are
            unopened. If you choose not to open our newsletters then over time
            we will take you off our lists without being prompted.
          </p>
          <h4>Protection of your personal data</h4>
          <p>
            We store your personal data on password-protected systems and will
            never share this information with third parties unless we have to
            for the fulfilment of orders. When you make a payment online, we do
            not store your card details ourselves. The payment is processed
            independently with your bank provider or PayPal.
          </p>
          <h4>Sensitive personal data</h4>
          <p>
            Data Protection law recognises that certain categories of personal
            information are more sensitive such as health information, race,
            religious beliefs and political opinions. We do not collect this
            type of information. We only connect information that is necessary
            to process your order.
          </p>
        </SectionContent>
      </Section>
    </Layout>
  );
};
export default PrivacyPolicy;
